import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import { Task, TaskFormInlineGroupValueItem, Variable } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

@Injectable()
export class PayrollVariableAbsenceComponentService {
  constructor(private readonly controlPanelTasksStateService: ControlPanelTasksStateService) {}

  task!: Task;
  partner!: TaskFormInlineGroupValueItem;
  variable!: Variable;

  private readonly _workStoppingAttachments: WritableSignal<Attachment[]> = signal([]);
  private readonly _workAccidentDeclarationAttachments: WritableSignal<Attachment[]> = signal([]);
  workStoppingAttachments$: Signal<Attachment[]> = this._workStoppingAttachments.asReadonly();
  workAccidentDeclarationAttachments$: Signal<Attachment[]> = this._workAccidentDeclarationAttachments.asReadonly();

  init() {
    if (!this.task || !this.variable || !this.partner) {
      return;
    }

    this._workStoppingAttachments.set(this.filterAttachments(this.task.attachments, 'workStopping'));
    this._workAccidentDeclarationAttachments.set(
      this.filterAttachments(this.task.attachments, 'workAccidentDeclaration'),
    );
  }

  async addAttachment(file: File, fileType: string): Promise<void> {
    const attachment: Attachment | void = await this.controlPanelTasksStateService.uploadTaskAttachment(
      this.task,
      file,
      {
        fileType,
        metadata: {
          partnerId: this.partner.partnerId,
          variableCreatedAt: this.variable.createdAt,
        },
      },
    );
    if (!attachment) {
      return;
    }

    if (fileType === 'workStopping') {
      this._workStoppingAttachments.update((attachments) => [...attachments, attachment]);
    } else if (fileType === 'workAccidentDeclaration') {
      this._workAccidentDeclarationAttachments.update((attachments) => [...attachments, attachment]);
    }
  }

  async removeAttachment(attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(this.task, attachment);
    this.task.attachments = this.task.attachments.filter((taskAttachment) => taskAttachment.id === attachment.id);

    if (attachment.type === 'workStopping') {
      this._workStoppingAttachments.update((attachments) =>
        attachments.filter((taskAttachment) => taskAttachment.id !== attachment.id),
      );
    } else if (attachment.type === 'workAccidentDeclaration') {
      this._workAccidentDeclarationAttachments.update((attachments) =>
        attachments.filter((taskAttachment) => taskAttachment.id !== attachment.id),
      );
    }
  }

  filterAttachments(attachments: Attachment[], type: string): Attachment[] {
    return attachments.filter(
      (attachment) =>
        attachment.type === type &&
        attachment.metadata?.partnerId === this.partner.partnerId &&
        attachment.metadata?.variableCreatedAt === this.variable.createdAt,
    );
  }
}
