import { CommonModule, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { differenceInDays, endOfMonth, startOfMonth } from 'date-fns';
import { FormService } from '@dougs/core/form';
import { generateUuidV4 } from '@dougs/core/utils';
import {
  DividerComponent,
  FileInputComponent,
  FilePillComponent,
  InputDatepickerComponent,
  RadioComponent,
  RadioGroupComponent,
} from '@dougs/ds';
import { Task, TaskFormInlineGroupValueItem, Variable, VariableData } from '@dougs/task/dto';
import { PayrollVariableAbsenceComponentService } from '../../../../services/modals/payroll-variable-absence.component.service';
import { controlContainerFactory } from '../control-container-factory/control-container-factory';

@Component({
  selector: 'dougs-payroll-variable-absence',
  templateUrl: './payroll-variable-absence.component.html',
  styleUrls: ['./payroll-variable-absence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: controlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    InputDatepickerComponent,
    FormsModule,
    CommonModule,
    RadioGroupComponent,
    RadioComponent,
    FileInputComponent,
    NgFor,
    FilePillComponent,
    DividerComponent,
  ],
  providers: [PayrollVariableAbsenceComponentService],
})
export class PayrollVariableAbsenceComponent implements OnInit {
  private _variable!: Variable;
  private _month!: string;
  private _variableData!: VariableData;

  uuid = generateUuidV4();
  absenceStartDateMinDate: Date | null = null;
  absenceStartDateMaxDate: Date | null = null;
  absenceEndDateMinDate: Date | null = null;
  shouldShowAbsenceEndDuration = false;

  @Input() isEditable = true;

  @Input()
  set variableData(variableData: VariableData) {
    this._variableData = variableData;
    this.computeMinDate();
  }

  get variableData(): VariableData {
    return this._variableData;
  }

  @Input() set partner(partner: TaskFormInlineGroupValueItem) {
    this.payrollVariableAbsenceComponentService.partner = partner;
  }

  @Input()
  set task(task: Task) {
    this.payrollVariableAbsenceComponentService.task = task;
  }

  @Input()
  set month(month: string) {
    this._month = month;
    this.computeMaxDate();
    this.computeMinDate();
  }

  get month(): string {
    return this._month;
  }

  @Input()
  set variable(variable: Variable) {
    this._variable = variable;
    this.computeMinDate();
    this.payrollVariableAbsenceComponentService.variable = variable;
  }

  get variable(): Variable {
    return this._variable;
  }

  constructor(
    public formService: FormService,
    public payrollVariableAbsenceComponentService: PayrollVariableAbsenceComponentService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.payrollVariableAbsenceComponentService.init();
  }

  computeMaxDate(): void {
    this.absenceStartDateMaxDate = endOfMonth(new Date(this.month));
  }

  computeMinDate(): void {
    if (this.variable && this.month && this.variableData) {
      this.absenceStartDateMinDate = this.variable.value === 'paidVacation' ? startOfMonth(new Date(this.month)) : null;

      this.absenceEndDateMinDate = this.variableData?.absenceStartDate
        ? new Date(this.variableData.absenceStartDate)
        : startOfMonth(new Date(this.month));

      this.shouldShowAbsenceEndDuration =
        (this.variable.value === 'paidVacation' ||
          this.variable.value === 'leaveWithoutPay' ||
          this.variable.value === 'unjustifiedAbsence') &&
        !!this.variableData?.absenceStartDate &&
        !!this.variableData?.absenceEndDate &&
        differenceInDays(new Date(this.variableData.absenceStartDate), new Date(this.variableData.absenceEndDate)) < 0;

      this.cdr.markForCheck();
    }
  }
}
